import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function PaymentMethodLogo(props: {
  paymentMethod:
    | 'card'
    | 'sofort'
    | 'sepa_debit'
    | 'googlePay'
    | 'applePay'
    | 'paypal';
}) {
  const data = useStaticQuery(graphql`
    {
      paypal: file(relativePath: { eq: "payment/paypal.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, height: 15, quality: 100, layout: FIXED)
        }
      }
      sofort: file(relativePath: { eq: "payment/klarna-sofort.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, height: 31, quality: 100, layout: FIXED)
        }
      }
      card: file(relativePath: { eq: "payment/cardproviders.png" }) {
        childImageSharp {
          gatsbyImageData(width: 114, height: 24, quality: 100, layout: FIXED)
        }
      }
      googlePay: file(relativePath: { eq: "payment/google-pay.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, height: 32, quality: 100, layout: FIXED)
        }
      }
      applePay: file(relativePath: { eq: "payment/apple-pay.png" }) {
        childImageSharp {
          gatsbyImageData(width: 52, height: 33, quality: 100, layout: FIXED)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={data[props.paymentMethod].childImageSharp.gatsbyImageData}
      alt={props.paymentMethod}
    />
  );
}
