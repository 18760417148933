import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { Paragraph } from '../../common';

export const MarketingPermission = ({ onChange }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Form.Check
      custom
      className="form-check-checkbox"
      type="checkbox"
      id="checkoutMarketing"
      data-test-id="marketing-permissions"
      checked={checked}
      label={
        <div className="form-check-label-wrapper">
          <Paragraph>
            <FormattedMessage
              id="stripe-checkout.marketing"
              defaultMessage="I'd like to receive news and updates from Dance."
            />
          </Paragraph>
        </div>
      }
      onChange={() => {
        setChecked(!checked);
        onChange();
      }}
    />
  );
};
