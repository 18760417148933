import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import { CardElement, IbanElement } from '@stripe/react-stripe-js';
import { Form } from 'react-bootstrap';
import '../stripecheckout.scss';

import { Options, Option } from '../../../common';

import PaymentMethodLogo from '../payment-method-logo';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { ErrorMessage } from '@/components/common/form/feedback';

type PaymentInputProps = {
  paymentMethod: string;
  onChange: (method: string) => void;
  error?: { message: string };
  setError: (error: StripeElementChangeEvent['error']) => void;
  availablePaymenthMethods: {
    [key: string]: boolean;
  };
};

const PaymentInput = ({
  paymentMethod,
  onChange,
  error,
  setError,
  availablePaymenthMethods,
}: PaymentInputProps) => {
  const cardOptionRef = useRef<HTMLLabelElement>(null);
  const googlePayOptionRef = useRef<HTMLLabelElement>(null);
  const applePayOptionRef = useRef<HTMLLabelElement>(null);
  const paypalOptionRef = useRef<HTMLLabelElement>(null);
  const sofortOptionRef = useRef<HTMLLabelElement>(null);

  const stripeInputStyle = {
    base: {
      fontSize: '16px',
    },
    invalid: {
      color: '#be3216',
      iconColor: '#be3216',
    },
  };

  const {
    googlepayEnabled,
    applepayEnabled,
    sepa_debitEnabled,
    sofortEnabled,
    paypalEnabled,
  } = availablePaymenthMethods;

  const sepaOptions = { supportedCountries: ['SEPA'], stripeInputStyle };

  const showMultiplePaymentMethods =
    Object.keys(availablePaymenthMethods).length > 1;

  const className = showMultiplePaymentMethods
    ? 'multipleMethods'
    : 'singleMethod';

  useEffect(() => {
    if (!!error && paymentMethod === 'applePay') {
      applePayOptionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (!!error && paymentMethod === 'googlePay') {
      googlePayOptionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (!!error && paymentMethod === 'card') {
      cardOptionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (!!error && paymentMethod === 'paypal') {
      paypalOptionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    if (!!error && paymentMethod === 'sofort') {
      sofortOptionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [error]);

  const handleChange = (e) => {
    setError(undefined);

    if (e.error) {
      setError(e.error);
    }

    if (e.elementType === 'card') {
      onChange('card');
    } else if (e.elementType === 'iban') {
      onChange('sepa_debit');
    }
  };

  const hasPaymenthMethodError = (method: string) =>
    !!error && paymentMethod === method;

  return (
    <Form.Group className={`payment-input ${className}`}>
      <Options>
        {googlepayEnabled && (
          <>
            <Option
              ref={googlePayOptionRef}
              dataTestId="payment-option-googlepay"
              key="googlePay"
              multiple={false}
              id="googlePay"
              section="PaymentMethod"
              selected={paymentMethod === 'googlePay'}
              onClick={() => {
                setError(undefined);
                onChange('googlePay');
              }}
            >
              <div className="payment-input-method">
                <FormattedMessage
                  id="stripe-checkout.payment-google-pay-choice"
                  defaultMessage="Google Pay"
                />
                <PaymentMethodLogo paymentMethod="googlePay" />
              </div>
            </Option>

            {hasPaymenthMethodError('googlePay') && (
              <ErrorMessage msg={error?.message} />
            )}
          </>
        )}

        {applepayEnabled && (
          <>
            <Option
              ref={applePayOptionRef}
              dataTestId="payment-option-applepay"
              key="applePay"
              multiple={false}
              id="applePay"
              section="PaymentMethod"
              selected={paymentMethod === 'applePay'}
              onClick={() => {
                setError(undefined);
                onChange('applePay');
              }}
            >
              <div className="payment-input-method">
                <FormattedMessage
                  id="stripe-checkout.payment-apple-pay-choice"
                  defaultMessage="Apple Pay"
                />
                <PaymentMethodLogo paymentMethod="applePay" />
              </div>
            </Option>
            {hasPaymenthMethodError('applePay') && (
              <ErrorMessage msg={error?.message} />
            )}
          </>
        )}

        <Option
          ref={cardOptionRef}
          dataTestId="payment-option-card"
          isInvalid={hasPaymenthMethodError('card')}
          key="card"
          multiple={false}
          id="card"
          section="PaymentMethod"
          selected={paymentMethod === 'card'}
          onClick={() => {
            setError(undefined);
            onChange('card');
          }}
          bottomSection={
            paymentMethod === 'card' ? (
              <CardElement
                className="form-control form-control__stripe"
                options={{ style: stripeInputStyle }}
                onChange={handleChange}
              />
            ) : undefined
          }
        >
          <div className="payment-input-method">
            <FormattedMessage
              id="stripe-checkout.payment-card-choice"
              defaultMessage="Credit Card"
            />
            <PaymentMethodLogo paymentMethod="card" />
          </div>
        </Option>

        {hasPaymenthMethodError('card') && (
          <ErrorMessage msg={error?.message} />
        )}

        {paypalEnabled && (
          <Option
            ref={paypalOptionRef}
            dataTestId="payment-option-paypal"
            key="paypal"
            multiple={false}
            isInvalid={hasPaymenthMethodError('paypal')}
            id="paypal"
            section="PaymentMethod"
            selected={paymentMethod === 'paypal'}
            onClick={() => {
              setError(undefined);
              onChange('paypal');
            }}
          >
            <div className="payment-input-method">
              <FormattedMessage
                id="stripe-checkout.payment-paypal-choice"
                defaultMessage="Paypal"
              />
              <PaymentMethodLogo paymentMethod="paypal" />
            </div>
          </Option>
        )}

        {hasPaymenthMethodError('paypal') && (
          <ErrorMessage msg={error?.message} />
        )}

        {sepa_debitEnabled && (
          <Option
            dataTestId="payment-option-sepa_debit"
            key="sepa_debit"
            multiple={false}
            id="sepa_debit"
            section="PaymentMethod"
            selected={paymentMethod === 'sepa_debit'}
            onClick={() => {
              setError(undefined);
              onChange('sepa_debit');
            }}
            bottomSection={
              paymentMethod === 'sepa_debit' ? (
                <IbanElement
                  className="form-control form-control__stripe"
                  options={sepaOptions}
                  onChange={handleChange}
                />
              ) : undefined
            }
          >
            <div className="payment-input-method">
              <FormattedMessage
                id="stripe-checkout.payment-sepa-debit-choice"
                defaultMessage="SEPA Direct Debit"
              />
            </div>
          </Option>
        )}

        {sofortEnabled && (
          <Option
            ref={sofortOptionRef}
            dataTestId="payment-option-sofort"
            key="sofort"
            multiple={false}
            id="sofort"
            section="PaymentMethod"
            selected={paymentMethod === 'sofort'}
            isInvalid={hasPaymenthMethodError('sofort')}
            onClick={() => {
              setError(undefined);
              onChange('sofort');
            }}
          >
            <div className="payment-input-method">
              <FormattedMessage
                id="stripe-checkout.payment-sofort-choice"
                defaultMessage="Sofortüberweisung"
              />
              <PaymentMethodLogo paymentMethod="sofort" />
            </div>
          </Option>
        )}

        {hasPaymenthMethodError('sofort') && (
          <ErrorMessage msg={error?.message} />
        )}
      </Options>
    </Form.Group>
  );
};

export default PaymentInput;
