import React, { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import StripeCheckout from './stripecheckout';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import { Section, SectionHeadline, SectionSubheadline } from '../../common';
import './payment.scss';
import { PersonalDetails } from './personal-details';
import { PurchaseDetails } from './purchase';
import { PaymentScheme } from '@/webapi/gen/graphql';

const Payment: FC = () => {
  const {
    checkoutState,
    config: { stripeKey },
  } = useCheckoutState();

  const { yearlyPrepayment } = checkoutState.priceDetail;
  let defaultScheme = 'YEARLY_PREPAID';
  if (yearlyPrepayment && yearlyPrepayment.percentOff <= 0) {
    defaultScheme = 'MONTHLY';
  }

  const [paymentScheme, setPaymentScheme] = useState<PaymentScheme>(
    (sessionStorage.getItem('paymentScheme') as PaymentScheme) ?? defaultScheme
  );

  return (
    <Fragment>
      <Section>
        <SectionHeadline>
          <FormattedMessage
            id="payment.page-title"
            defaultMessage="Your order"
          />
        </SectionHeadline>
        <SectionSubheadline>
          <FormattedMessage
            id="payment.page-subtitle"
            defaultMessage="Please make sure everything is correct."
          />
        </SectionSubheadline>

        <PersonalDetails />
        <hr className="payment-hr" />
        <PurchaseDetails
          paymentScheme={paymentScheme}
          setPaymentScheme={setPaymentScheme}
        />
      </Section>

      <StripeCheckout
        checkoutId={checkoutState.id}
        billingName={`${checkoutState.payload.firstname} ${checkoutState.payload.lastname}`}
        billingEmail={checkoutState.email}
        billingLine1={checkoutState.payload.billingLine1}
        billingLine2={checkoutState.payload.billingLine2}
        billingCity={checkoutState.payload.billingCity}
        billingZipcode={checkoutState.payload.billingZipcode}
        billingCountry={checkoutState.payload.billingCountry}
        b2bCompany={checkoutState.b2bCompany}
        stripeKey={stripeKey}
        paymentScheme={paymentScheme}
      />
    </Fragment>
  );
};

export default Payment;
