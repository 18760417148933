import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';

import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import Payment from '../../components/checkout/payment-and-summary/payment';
import client from '@/webapi/client';

import { WithCheckout } from '../../hooks/useCheckoutState';

import { track } from '../../components/tracking/segment';

const PaymentPage = ({ checkoutId }) => {
  useEffect(() => {
    track('Checkout Step Viewed', {
      checkout_id: checkoutId,
      step_name: 'payment-details',
    });
  }, [checkoutId]);

  return (
    <ApolloProvider client={client}>
      <WithCheckout>
        <Layout withCheckoutFooter={false}>
          <Seo title="Dance" />
          <Payment />
        </Layout>
      </WithCheckout>
    </ApolloProvider>
  );
};

export default PaymentPage;
